import NavigationBar from "./NavigationBar";
import Header from "./header";

const BgTheme = ({children}) => {
    return (
  
        <div className="bg-solid h-full w-full">
           {children}
            </div>
    )
}

export default BgTheme;
