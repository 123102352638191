import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate hook
import important from "../src/assests/i-icon.svg";
import logo from "../src/assests/arab256.png";

const DialogBox = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const goToCreateYourCard = (cardType) => {
    navigate("/disclaimer", { state: { type: cardType } });
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="space-y-4">
        <div className="flex items-center justify-center">
          <img src={logo} alt="logo" className=""/>
        </div>
        <div className="rounded-lg gray-border w-96 md:w-607">
          <div className="text-white">
            <div className="flex flex-col items-center">
              {/* heading */}
              <div className="flex items-center justify-start p-4">
                <h2 className="text-2xl font-bold mb-3">
                  Welcome to Arab Cat Cards!
                </h2>
              </div>
              {/* body */}
              <div className="flex flex-col items-center px-4 pb-4 w-96 md:w-607">
                <p className="pb-5">
                  Purchase prepaid debit cards or gift cards with your crypto in just a few clicks.
                </p>

                <div className="flex items-center justify-center pb-5 h-40">
                  <div className="warning-frame">
                    <div className="ps-4 pt-3 flex items-center">
                      <span><img src={important} alt="important" className="max-h-6 warn-text"/></span><span className="warn-text ps-3">Tokens Needed!</span>
                    </div>
                    <div className="ps-11 flex items-start text-white pb-5">
                      <span>To purchase cards you must have at least 5000 ArabCat Tokens in your connected wallet!</span>
                    </div>
                  </div>
                </div>

                <button
                  className="green-btn w-7/12"
                  onClick={() => goToCreateYourCard("gift")}
                >
                  Create your Gift Card →
                </button>
                <div className="pb-5"></div>
                <button
                  className="pink-btn w-7/12"
                  onClick={() => goToCreateYourCard("prepaid")}
                >
                  Create your Prepaid Card →
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;

