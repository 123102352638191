import React, { useState, useEffect } from "react";
import ChipIcon from "../src/assests/chip.svg";
import chipPurple from "../src/assests/chip-purple.svg";
import ChipGreen from "../src/assests/chip-green.svg";
import { useNavigate, useLocation } from "react-router-dom";

const OfferCard = ({
  chip,
  providerName,
  amount,
  cardNumber,
  name,
  currency,
  onClick
}) => {
  return (
    <div
      className="display-card rounded-md card-select overflow-hidden text-white"
      onClick={onClick} // Use the onClick prop here
    >
      <div className="absolute"></div>
      <div className="relative p-3">
        <div className="flex justify-between items-start">
          <span className="flex justify-between items-center ">
            <img src={chip} alt="Chip" className="w-10 h-10" />
          </span>
          <span className="pt-2">
            {providerName}
          </span>
        </div>
        
        <div className="flex flex-col items-start justify-between mt-4">
          <div className="text-2xl font-bold"><small>{cardNumber}</small></div>
          <div className="flex justify-between items-center w-full mt-4">
            <div className="text-lg">{name}</div>
            <div className="text-2xl font-bold">{`${currency} ${amount}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OfferCards = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Access location object
  const { provider, selectedCountry, email, cardType } = location.state;
  const chips = [ChipGreen, chipPurple];

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  let firstname = ["Kai", "Eliana", "Jaden", "Ezra", "Luca", "Rowan", "Nova", "Amara", "Aaliyah", "Finn"];
	let lastname= ["Smith", "Johnson", "Williams", "Brown", "Jones", "Garcia", "Miller", "Davis", "Martinez", "Wilson"];
  let amounts = provider?.amounts?.split(",");
  var i = amounts.length
  while (i--) {
    if (parseInt(amounts[i]) < 20) { 
      amounts.splice(i, 1);
    } 
  }

  useEffect(() => {
    if (!provider) {
      // Navigate back or show an error message
      navigate("/select-provider", { state: { country: selectedCountry, email: email, type: cardType } });
    }
  }, [provider, navigate]);

  useEffect(() => {
  }, []);
  const goToSelectCountry = () => {
    navigate("/select-country", { state: { type: cardType } });
  };

  const handleCardSelection = (card) => {
    navigate("/select-currency", { state: { currency: card.currency, provider: card.provider, amount: card.amount, email: email, type: cardType } });
  };

  return (
    <div className="relative h-full flex items-center justify-center p-2">
      <div>
      <div className="flex justify-center pb-2">
        <div className="step-box border-fuchsia-800">
          <div className="step-text flex justify-center items-center">
            4
          </div>
        </div>
      </div>
      <div>
        <div className="text-white flex justify-center items-center pb-1">
          <strong>Step 4</strong>
        </div>
        <div className="gray-text flex justify-center items-center pb-4">
          Select an amount for your card
        </div>
        <hr className="step-divider"></hr>
        <div className="pb-2"></div>
      </div>
      <div className="flex flex-wrap items-start justify-center sm:justify-start gap-2 sm:gap-4 h-100 pt-2 overflow-y-auto w-full pb-20 w-96 lg:w-920 scroll-content">
        {provider ? (
          amounts.map((amount, index) => (
            <OfferCard
              key={index}
              chip={chips[Math.floor(Math.random() * chips.length)]}
              providerName={provider.provider}
              amount={amount.trim()}
              cardNumber= {(getRandomInt(9999) + " xxxx xxxx " + getRandomInt(9999))}
              name={firstname[Math.floor(Math.random()*firstname.length)] + " " + lastname[Math.floor(Math.random()*lastname.length)]}
              currency={provider.currency}
              onClick={() => handleCardSelection({
                currency: provider.currency,
                provider: provider.provider,
                amount: amount.trim()
              })}
            />
          ))
        ) : (
          <div>Provider information is unavailable</div>
        )}
      </div>
      <div className="flex justify-start">
        <button
          onClick={goToSelectCountry}
          className="hover:bg-opacity-80 text-white font-bold back-card"
        >
          ← <small>Back</small>
        </button>
      </div>
      </div>
    </div>

  );

};

export default OfferCards;
