import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import visaCard from "../src/assests/visa.svg";
import mastercardCard from "../src/assests/mastercard.svg";
import important from "../src/assests/i-icon.svg";

const SelectProvider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCountryCode = location.state?.country?.value;
  const selectedCountry = location.state?.country;
  const email = location.state.email;
  const cardType  = location.state.type;
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await fetch(`https://paypawcards.com/paypawbackend/get-providers/${selectedCountryCode}`);
        if (response.status == 404) {
          throw new Error('404');
        }
        if (!response.ok) {
          throw new Error('Failed to select provider information');
        }
        const data = await response.json();
        setProviders(data); // Assuming the API returns an array of providers
      } catch (error) {
        if (error.message == '404') {
          toast.error("Error selecting provider - This contry currently does not have any cards available for use!", {
            position: "top-left"
          });
        }
        console.error("Failed to fetch providers", error);
      }
    };

    if (selectedCountryCode) {
      fetchProviders();
    }
  }, [selectedCountryCode]);

  const goToSelectCountry = () => {
    navigate("/select-country", { state: { type: cardType } });
  };

  // Updated to accept provider information
  const goToOfferCards = (provider) => {
    navigate("/offer-cards", { state: { provider, selectedCountry, email } });
  };

  return (
    <div className="relative h-full flex items-center justify-center p-2">
      <div>
        
      <div className="flex justify-center pb-2">
        <div className="step-box border-fuchsia-800">
          <div className="step-text flex justify-center items-center">
            3
          </div>
        </div>
      </div>
      <div>
        <div className="text-white flex justify-center items-center pb-1">
          <strong>Step 3</strong>
        </div>
        <div className="gray-text flex justify-center items-center pb-4">
          Select a provider for your card
        </div>
        <hr className="step-divider"></hr>
        <div className="pb-2"></div>
      </div>
      <div className="flex items-center justify-center pb-5 h-40">
        <div className="warning-frame">
          <div className="ps-4 pt-3 flex items-center">
            <span><img src={important} alt="important" className="max-h-6 warn-text"/></span><span className="warn-text ps-3">Warning!</span>
          </div>
          <div className="ps-11 flex items-start text-white pb-5">
            <span>Warning! DO NOT use a VPN/Proxy or Tor when trying to redeem your cards, in order to avoid getting blocked. This also includes Brave Shield, Adblock, Umatrix etc.</span>
          </div>
        </div>
      </div>
        <div className="flex-1 flex flex-wrap items-start justify-center sm:justify-start gap-2 sm:gap-4 h-100 pt-2 overflow-y-auto w-full pb-20 w-96 lg:w-880">
          {providers.map((provider) => (
            <div
              key={provider.provider + provider.brand + provider.currency} // Assuming logo URLs are unique
              className="display-card card-select"
              onClick={() => goToOfferCards(provider)}
            >
              <div>
                <img
                  src={provider.brand === "Visa" ? visaCard : mastercardCard}
                  alt={provider.provider}
                  className="ps-5 pt-1 h-12"
                />
                <div className="text-white ps-5 pt-1 pb-2">
                  <div className="flex justify-left">
                    <span className="font-bold">{provider.provider}</span>
                  </div>
                  <div className="flex justify-left flex justify-between">
                    <span className="gray-text text-s">Provider</span><span>{provider.kyc ? <small className="pe-2 gray-text">(may require kyc)</small> :""}</span>
                  </div>
                  
                </div>
                <div className="text-white ps-2">
                  <div className="provider-card-currency flex justify-between">
                    <span className="ps-3">Currency</span><span className="pe-3">{provider.currency === "USD" ? "USD - $" : "EURO - €"}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-start">
          <button
            onClick={goToSelectCountry}
            className="hover:bg-opacity-80 text-white font-bold back-card"
          >
            ← <small>Back</small>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectProvider;
