import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from 'react-select'
import check from "../src/assests/check.svg";

const EnterStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tradeID, setTradeID] = useState("");
  const [tradeStatus, setTradeStatus] = useState("");
  const [tradeIDValid, setTradeIDValid] = useState(false);
  const [lookingUpTradeID, setLookingUpTradeID] = useState(false);

  useEffect(() => {
    
  }, []);

  const validateTradeID = (e) => {
    setTradeID(e.target.value)

    if((e.target?.value && e.target.value.length == 10) || (e.target.value == "" || e.target.value == null)) {
      setTradeIDValid(true);
    } else {
      setTradeIDValid(false);
    }
  }

  const checkTradeID = async () => {
    setLookingUpTradeID(true);
    try {
      const response = await fetch(`https://paypawcards.com/paypawbackend/trade-status-lookup/${tradeID}?source=arabcat`);
      
      if (response.status == 404) {
        throw new Error('404');
      }
      if (!response.ok) {
        toast.error("Failed to lookup TradeID!", {
          position: "top-left"
        });
        setLookingUpTradeID(false);
        throw new Error('Failed to lookup tradeid');
      }
      const data = await response.json();

      setTradeStatus(data?.status); 
    } catch (error) {
      console.error("Failed to lookup tradeid", error);
    }
  };

  return (
    <div className="flex justify-center items-center">
      
      <div className="absolute flex justify-center items-center mx-8 sm:mx-0 rounded-lg">
        <div>
          <div className="flex flex-col items-center">
            <div className="w-96 md:w-607 email-frame flex justify-center text-white">
              <div className="bg-nav-bar text-white sm:max-w-md rounded-lg">
      
                <div className="flex justify-center items-center p-4"> 
                  <h2 className="text-lg font-semibold">Order Lookup</h2>
                </div>

                { 
                  tradeStatus === "" ?
                  <div>
                    <p>Orders can take 15minutes up to two hours. If the card link is ready for you it will be emailed to the email provided when ordering. If you have any additional issues please contact support.</p>
                  
                    <div className="flex justify-center items-center pb-5 pt-5">
                      <input type="text" onChange={validateTradeID} id="tradeid" className="w-96 md:w-607 required border gray-border text-white bg-nav-bar text-sm rounded-lg p-2.5" placeholder="TradeID for lookup" required />
                    </div>
                  </div>
                  :
                  ''
                }
                
                
                { 
                  tradeIDValid === false ?
                  <div className="flex justify-center items-center"> 
                    <span className="text-red-700">* TradeID Invalid</span>
                  </div>
                  :
                  ''
                }

                { 
                  (tradeID == "") ?
                  <div className="flex justify-center items-center pt-3 pb-3"> 
                    <span className="text-white"><i>Please Enter your TradeID.</i></span>
                  </div>
                  :
                  ''
                }

                { 
                  (tradeStatus != "" && (tradeStatus != "finished" && tradeStatus != "waiting")) ?
                  <div className="pt-3 pb-3">
                    <div className="flex justify-center">
                      ORDER: {tradeID}
                    </div>
                    <br/>
                    <div className="flex justify-center text-white"><i>Currently Awaiting Card Creation...</i></div>
                  </div>
                  :
                  ''
                }
                { 
                  (tradeStatus == "waiting") ?
                  <div className="pt-3 pb-3">
                    <div className="flex justify-center">
                      ORDER: {tradeID}
                    </div>
                    <br/>
                    <div className="flex justify-center text-white"><i>Currently Awaiting Payment...</i></div>
                  </div>
                  :
                  ''
                }
                { 
                  (tradeStatus == "finished") ?
                  <div className="pt-3 pb-3"> 
                    <div className="flex justify-center">
                      ORDER: {tradeID}
                    </div>
                    <br/>
                    <div className="flex justify-center text-white">Card Created! Your redemption information was sent to you via the email you used when placing the order.</div>
                    <br/>
                    <div className="flex justify-center">
                      <i>Warning! Do NOT use a VPN or Tor when redeeming, or your redeem code may be blocked! Also avoid using AdBlockers or Brave Shield, or you might get errors from the provider.</i>
                    </div>
                  </div>
                  :
                  ''
                }
              </div>
            </div>
            
            { 
              tradeStatus === "" ?
              <div className="pt-4">
                <button
                  onClick={checkTradeID}
                  className={`w-96 md:w-607 confirm-btn`}
                  disabled={tradeIDValid !== true && lookingUpTradeID !== true}
                >
                  <span className="text-white flex justify-center items-center">Check Order Status</span>
                </button>
              </div>
              :
              ''
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterStatus;
