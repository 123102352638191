import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select from 'react-select'
import check from "../src/assests/check.svg";

const EnterEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCountryCode = location.state?.country;
  const cardType  = location.state?.type;
  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailsMatching, setEmailsMatching] = useState(false);
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  useEffect(() => {
    setEmailIsValid(true);
  }, []);

  const validateEmail = (e) => {
    setEmail(e.target.value)

    if((e.target?.value && e.target.value.match(isValidEmail)) || (e.target.value == "" || e.target.value == null)) {
      setEmailIsValid(true);
    } else {
      setEmailIsValid(false);
    }
  }

  const validateEmailMatch = (e) => {
    if(e.target?.value && e.target?.value && (e.target.value === email)){
      setEmailsMatching(true);
    }else{
      setEmailsMatching(false);
    }
  }

  const goToProviders = () => {
    if (cardType === 'gift') {
      navigate("/select-gift-card", { state: { country: selectedCountryCode, email: email, type: cardType } });
    } else {
      navigate("/select-provider", { state: { country: selectedCountryCode, email: email, type: cardType } });
    }
  };

  return (
    <div className="flex justify-center items-center">
      
      <div className="absolute flex justify-center items-center mx-8 sm:mx-0 rounded-lg">
        <div>
          <div className="flex flex-col items-center">
    
            <div className="flex justify-center pb-2">
              <div className="step-box border-fuchsia-800">
                <div className="step-text flex justify-center items-center">
                  2
                </div>
              </div>
            </div>
            <div>
              <div className="text-white flex justify-center items-center pb-1">
                <strong>Step 2</strong>
              </div>
              <div className="gray-text flex justify-center items-center pb-4">
                Enter your email
              </div>
              <hr className="step-divider"></hr>
              <div className="pb-2"></div>
            </div>

            <div className="w-96 md:w-607 email-frame flex justify-center text-white">
              <div className="bg-nav-bar text-white sm:max-w-md rounded-lg">
      
                <div className="flex justify-center items-center p-4"> 
                  <h2 className="text-lg font-semibold"></h2>
                </div>
                
                <div className="flex justify-center items-center">
                  <input type="email" onChange={validateEmail} id="email" value={email} className="w-96 md:w-607 required border gray-border text-white bg-nav-bar text-sm rounded-lg p-2.5" placeholder="name@email.com" required />
                </div>
                
                { 
                  emailIsValid === false ?
                  <div className="flex justify-center items-center"> 
                    <span className="text-red-700">* Email Invalid</span>
                  </div>
                  :
                  ''
                }
                { 
                  emailIsValid === true && email !== "" ?
                    <div>
                      <div className="flex justify-center items-center p-4"> 
                        <h2 className="text-lg font-semibold">Verify Email</h2>
                      </div>
                      <div className="flex justify-center items-center pb-3">
                        <input type="email" id="email-match" onChange={validateEmailMatch} className="w-96 md:w-607 required border gray-border text-white bg-nav-bar text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" placeholder="name@email.com" required />
                      </div>
                    </div>
                  :
                  ''
                }
                { 
                  (emailsMatching === false && emailIsValid === true && email !== "") ?
                  <div className="flex justify-center items-center pb-3"> 
                    <span className="text-red-700">* Emails must match </span>
                  </div>
                  :
                  ''
                }

                { 
                  (email == "") ?
                  <div className="flex justify-center items-center pt-3 pb-3"> 
                    <span className="text-white"><i>Please Enter your email.</i></span>
                  </div>
                  :
                  ''
                }
              </div>
            </div>
            
            <div className="pt-4">
              <button
                onClick={goToProviders}
                className={`w-96 md:w-607 confirm-btn ${cardType === 'gift' ? "green-btn" : "pink-btn"}`}
                disabled={emailIsValid !== true || (emailsMatching !== true)}
              >
                <span className="text-white flex justify-center items-center"><img src={check} alt="check" height="12" width="12"/><span className="ps-3">Continue</span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterEmail;
