import React from "react";
import { useLocation } from "react-router-dom";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const GetYourCard = () => {
  const location = useLocation();
  const { redeemLink, redeemCode } = location.state;

  const handleRedeem = () => {
    window.open(redeemLink, "_blank"); // Open the redeem link in a new tab
  };
  const { width, height } = useWindowSize();

  return (
    <div className="bg-nav-bar gray-border rounded-lg text-white text-center shadow-xl w-full  mx-8 sm:mx-0">
      <Confetti
        width={width}
        height={height}
        numberOfPieces={4000}
        recycle={false}
      />
      <h2 className="text-2xl font-bold p-4 flex items-center justify-start mb-4">
        <span className="mr-2">🎯</span>
        Get Your Card
      </h2>
      <p className="text-lg mb-6">Your card has been created successfully!</p>
      <br/>
      <br/>
      <div className="flex justify-center">
        <i>Warning! Do NOT use a VPN or Tor when redeeming, or your redeem code may be blocked! Also avoid using AdBlockers or Brave Shield, or you might get errors from the provider.</i>
      </div>
      <br/>
      <button
        onClick={handleRedeem}
        className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        Redeem Your Card
      </button>
      <div className="text-white flex justify-center p-3">
        <i>Card Redeem Code: {redeemCode}</i>
      </div>
    </div>
  );
};

export default GetYourCard;
