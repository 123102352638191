import React, { useState, FC, useMemo, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./header";
import NavigationBar from "./NavigationBar";
import DialogBox from "./DialogBox";
import DisclaimerWarning from "./DisclaimerWarning";
import SelectProvider from "./SelectProvider";
import SelectCurrency from "./SelectCurrency";
import OfferCards from "./OffersCards";
import PaymentQrCode from "./PaymentQrCode";
import PaymentForm from "./PaymentForm";
import SuccessfulPayment from "./SuccessfulPayment";
import GetYourCard from "./GetYourCard";
import LoginForm from "./LoginForm";
import HistoryTable from "./HistoryTable";
import ScrollableDialogBox from "./selectCountryDialogBox";
import EnterEmail from "./enterEmail";
import EnterStatus from "./enterStatus";
import SelectGiftCard from "./SelectGiftCard";
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  UnsafeBurnerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import * as buffer from "buffer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@fontsource/inter";

window.Buffer = buffer.Buffer;
window.buffer = buffer;

require('@solana/wallet-adapter-react-ui/styles.css');


window.Buffer = buffer.Buffer;

function App() {
  const [mobile, setMobile] = useState(window.innerWidth < 640);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth < 640);
    setNavbarVisible(window.innerWidth >= 640);
  }

  useEffect(() => {
    setNavbarVisible(!mobile);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const [navbarVisible, setNavbarVisible] = useState(true);

  const toggleNavbarVisibility = () => {
    setNavbarVisible(!navbarVisible);
  };
  const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // updating this to include additional wallets
    const wallets = useMemo(() => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter()
    ], [network]
);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <ToastContainer />
          <Router>
            <link href="https://cdn.jsdelivr.net/npm/toastr/build/toastr.min.css" rel="stylesheet" />
            <script src="https://cdn.jsdelivr.net/npm/toastr/build/toastr.min.js"></script>
            <div className="flex h-lvh">
              {/* nav-area */}
              <div className="">
                <NavigationBar
                  navbarVisible={navbarVisible}
                  toggleNavbarVisibility={toggleNavbarVisibility}
                />
              </div>
              {/* main-area */}
              <div className="w-full overflow-x-hidden overflow-y-auto sm:overflow-y-hidden">
                <Header toggleNavbarVisibility={toggleNavbarVisibility} />
                {/* content-area*/}
                <div className="relative h-full flex items-center justify-center p-2">
                  <Routes>
                    <Route path="/" element={<DialogBox />} exact />
                    <Route path="/home" element={<DialogBox />} />
                    <Route path="/disclaimer" element={<DisclaimerWarning />} />
                    <Route path="/select-provider" element={<SelectProvider />} />
                    <Route path="/offer-cards" element={<OfferCards />} />
                    <Route path="/payment-qr-code" element={<PaymentQrCode />} />
                    <Route path="/payment-form" element={<PaymentForm />} />
                    <Route path="/successful-payment" element={<SuccessfulPayment />} />
                    <Route path="/get-your-card" element={<GetYourCard />} />
                    <Route path="/login-form" element={<LoginForm />} />
                    <Route path="/history-table" element={<HistoryTable />} />
                    <Route path="/select-country" element={<ScrollableDialogBox />} />
                    <Route path="/select-currency" element={<SelectCurrency />} />
                    <Route path="/email-for-card" element={<EnterEmail />} />
                    <Route path="/check-trade-id" element={<EnterStatus />} />
                    <Route path="/select-gift-card" element={<SelectGiftCard />} />
                  </Routes>
                </div>
              </div>
            </div>
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
