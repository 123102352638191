import React, { useState, useEffect } from "react";
import solLogo from "../src/assests/currency-sol-logo.png";
import btcLogo from "../src/assests/currency-btc-logo.png";
import ethLogo from "../src/assests/currency-eth-logo.png";
import bonkLogo from "../src/assests/currency-bonk-logo.png";
import PawLogo from "../src/assests/paypawlogo.png";
import usdcLogo from "../src/assests/currency-usdc-logo.png";
import usdtLogo from "../src/assests/currency-usdt-logo.png";
import dogeLogo from "../src/assests/currency-doge-logo.png";
import shibLogo from "../src/assests/currency-shib-logo.png";
import pepeLogo from "../src/assests/currency-pepe-logo.png";
import polygonLogo from "../src/assests/currency-polygon-logo.svg";

const HistoryTable = () => {
  const [historyData, setHistoryData] = useState([]);

  const coins = 
  [
    {
      name: "SOL",
      ticker: "SOL",
      network: "Mainnet",
      logo: solLogo
    },
    {
      name: "ETH",
      ticker: "ETH",
      network: "ERC20",
      logo: ethLogo
    },
    {
      name: "BTC",
      ticker: "BTC",
      network: "Mainnet",
      logo: btcLogo
    },
    {
      name: "BONK",
      ticker: "BONK",
      network: "SOL",
      logo: bonkLogo
    },
    {
      name: "USD Coin (SOL)",
      ticker: "USDC",
      network: "SOL",
      logo: usdcLogo
    },
    {
      name: "USD Coin (ERC20)",
      ticker: "USDC",
      network: "ERC20",
      logo: usdcLogo
    },
    {
      name: "USD Coin (BSC)",
      ticker: "usdc",
      network: "BEP20",
      logo: usdcLogo
    },
    {
      name: "Tether (SOL)",
      ticker: "USDT",
      network: "SOL",
      logo: usdtLogo
    },
    {
      name: "Tether (ERC20)",
      ticker: "USDT",
      network: "ERC20",
      logo: usdtLogo
    },
    {
      name: "Tether (BSC)",
      ticker: "usdt",
      network: "BEP20",
      logo: usdtLogo
    },
    {
      name: "Dogecoin",
      ticker: "doge",
      network: "Mainnet",
      logo: dogeLogo
    },
    {
      name: "Shiba Inu (ERC20)",
      ticker: "shib",
      network: "ERC20",
      logo: shibLogo
    },
    {
      name: "Pepe (ERC20)",
      ticker: "pepe",
      network: "ERC20",
      logo: pepeLogo
    },
    {
      name: "Polygon (Polygon)",
      ticker: "matic",
      network: "Mainnet",
      logo: polygonLogo
    }
  ];

  const convertDate = (timestamp) => {
    let date = new Date(timestamp);

    let dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

    return date.toLocaleDateString('en-US', dateOptions);
  };

  const convertTime = (timestamp) => {
    let date = new Date(timestamp);

    let timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

    return date.toLocaleTimeString('en-US', timeOptions).replace(/\s/g, '');
  };

  function getLogoByTicker(ticker) {
    const coin = coins.find(coin => coin.ticker.toLowerCase() === ticker.toLowerCase());
    return coin ? coin.logo : "";
  }

  function getNameByTicker(ticker) {
    const coin = coins.find(coin => coin.ticker.toLowerCase() === ticker.toLowerCase());
    return coin ? coin.name : "";
  }

  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const response = await fetch(
          "https://paypawcards.com/paypawbackend/get-history-partner?source=arabcat"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        setHistoryData(data.sanitized);
      } catch (error) {
        console.error("Error fetching history data:", error);
      }
    };
    
    fetchHistoryData();
  }, []);

  return (
    <div className="absolute top-0 left-0 p-5 w-full h-full overflow-x-auto pb-20">
      <h1 className="text-white flex items-center justify-center pb-4">Last 25 Purchases</h1>
      <div className="flex flex-col w-96 md:w-full align-top">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="border rounded-lg shadow overflow-hidden border-border shadow-gray-900">
              <table className="min-w-full divide-y divide-border">
                <thead className="bg-table-dark">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Provider</th>
                    <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Amount</th>
                    <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Currency</th>
                    <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Purchased with</th>
                    <th scope="col" className="px-6 py-3 text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {historyData.map((history) => (
                    <tr
                      key={history.updated}
                      className="even:bg-table-dark"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">{history.provider}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{history.amount}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{history.currency}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                      <img
                        src={getLogoByTicker(history.ticker)}
                        alt={getNameByTicker(history.ticker)}
                        className="h-6 max-w-6"
                      />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 text-right"><span className="text-neutral-600">{convertTime(history.updated)} on </span> {convertDate(history.updated)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryTable;
