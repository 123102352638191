import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate hook
import check from "../src/assests/check.svg";

const DisclaimerWarning = () => {
  const location = useLocation();
  const cardType  = location.state.type;
  const navigate = useNavigate(); // Initialize the useNavigate hook
  
  const goToCreateYourCard = () => {
    navigate("/select-country", { state: { type: cardType } });
  };

  return (
    <div className="absolute bg-nav-bar flex justify-center items-center mx-8 sm:mx-0 rounded-lg gray-border overflow-y-auto">
      <div className="text-white">
        <div className="flex flex-col items-center">
          {/* heading */}
          <div className="flex items-center justify-start p-4">
            <h2 className="text-2xl font-bold mb-3">
              Welcome to PayPaw!
            </h2>
          </div>
          {/* body */}
          <div className="flex flex-col items-center px-4 pb-4">
            <p className="pb-5">
            <span className="flex items-center justify-center">⚠️ Disclaimer for Prepaid Cards ⚠️</span>
            <br/>
            - Card Types: We offer Mastercard in USD and Visa in EUR.
            <br/>
            - Acceptance:
            <br/>
            &emsp;- USD Mastercard is generally accepted by a broader range of merchants worldwide.
              <br/>
            &emsp;- EUR Visa typically works with larger companies.
              <br/>
            - Usage: Both cards can be used online and are compatible with Apple Pay and Google Pay.
            <br/>
            - Delivery Time: Receiving your prepaid card may take up to 2 hours. However, it is usually delivered within 15 minutes.
            <br/>
            <br/>
            Please note, these cards are digital and intended for online use only. Make sure you choose the card that best suits your needs based on the currency and acceptance criteria.
            <br/>
            <br/>
            Email is only used to provide you with a copy of your purchase. A valid email must be provided.
            <br/>
            <br/>
            By proceeding, you acknowledge and accept the terms and conditions related to the use of prepaid cards provided through our service.
            <br/>
            </p>
            <button
              className={`${cardType === 'gift' ? "green-btn" : "pink-btn"}`}
              onClick={goToCreateYourCard}
            >
              <span className="text-white flex justify-center items-center"><img src={check} alt="check" height="12" width="12"/><span className="ps-3">Confirm</span></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerWarning;
